<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Promotional item types">

                <b-button v-if="$hasPermission($permissions.AddPromotionalItems)" variant="primary" @click="addPromotionalItemTypeModalActive = true">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>


                <basic-table :columns="columns" :data="promotionalItemTypes" v-slot="props">
                    <div v-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button v-if="$hasPermission($permissions.EditPromotionalItems)" variant="warning" @click="openPromotionalItemTypeEdit(props.row)" class="mr-1">
                            <feather-icon icon="EditIcon"/>
                        </b-button>
                        <b-button v-if="$hasPermission($permissions.RemovePromotionalItems)" variant="danger" @click="removePromotionalItemType(props.row.id)" class="mr-1">
                            <feather-icon icon="Trash2Icon"/>
                        </b-button>
                    </div>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>

            </b-card>
        </b-overlay>

        <b-modal title="Add promotional item type" v-model="addPromotionalItemTypeModalActive" no-close-on-backdrop>
            <template #default>

                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="addObject.name"/>
                </b-form-group>

            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addPromotionalItemTypeModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addPromotionalItemType">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>

        <b-modal title="Edit promotional item type" v-model="editPromotionalItemTypeModalActive" no-close-on-backdrop>
            <template #default>
                <b-form-group>
                    <label>Name</label>
                    <b-form-input v-model="editObject.name"/>
                </b-form-group>
            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="editPromotionalItemTypeModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="warning" @click="savePromotionalItemType">
                    <feather-icon
                        icon="EditIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save</span>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>

    import {BButton, BCard, BFormGroup, BFormInput, BModal, BOverlay} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'

    export default {
        components: {
            BCard,
            BOverlay,
            BasicTable,
            BButton,
            BFormGroup,
            BFormInput,
            BModal
        },
        data() {
            return {
                dataLoaded: false,
                promotionalItemTypes: [],

                columns: [
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addPromotionalItemTypeModalActive: false,
                addObject: {
                    name: ''
                },

                editPromotionalItemTypeModalActive: false,
                editObject: {
                    name: ''
                }
            }
        },
        methods: {
            openPromotionalItemTypeEdit(object) {
                this.editObject = JSON.parse(JSON.stringify(object))
                this.editPromotionalItemTypeModalActive = true
            },
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/promotional_item_type')
                loadPromise.then(function(response) {
                    thisIns.promotionalItemTypes = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addPromotionalItemType() {
                const thisIns = this
                const addPromise = this.$http.post('/api/management/v1/promotional_item_type', this.addObject)
                addPromise.then(function() {

                    thisIns.$printSuccess('Promotional item type added')

                    thisIns.addPromotionalItemTypeModalActive = false
                    thisIns.addObject = {
                        name: ''
                    }
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            savePromotionalItemType() {
                const thisIns = this
                const savePromise = this.$http.put(`/api/management/v1/promotional_item_type/${  this.editObject.id}`, this.editObject)
                savePromise.then(function() {

                    thisIns.$printSuccess('Promotional item type saved')

                    thisIns.editPromotionalItemTypeModalActive = false
                    thisIns.editObject = {
                        name: ''
                    }
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            async removePromotionalItemType(id) {

                try {

                    const confirmDeletion = await this.$confirmDialog('Do you want to remove this promotional item type?')
                    
                    if (!confirmDeletion) return
                    await this.$http.delete(`/api/management/v1/promotional_item_type/${  id}`)
                    this.$printSuccess('Promotional item type removed')

                } catch (error) {
                    this.$printError((error.response) ? error.response.data : error)
                    
                } finally {
                    this.loadData()
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>